@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

div {
  color: white;
}

.App {
  min-height: 800px;
  overflow-y: scroll;
  position: relative;
}

header {
  display: flex;
  justify-content: space-between;
  color: #f1f1f1;
  grid-column-start: span 2;
  align-items: center;
  height: 6vh;
  min-height: 25px;
}

.landing-container {
  display: grid;
  width: 85%;
  margin: 0 auto;
  grid-template-columns: 100%;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

/* Normale Bildschirme kleingezogen */
@media screen and (max-width: 1350px) {
}

/* Kleine Endgeräte (Smartphones, kleiner als 768px) */
@media (max-width: 750px) {
  header {
    grid-column-start: unset;
  }

  .landing-container {
    height: auto;
    grid-template-rows: auto 100vh;
    row-gap: 3rem;
  }
}
