#navigator-ul {
    list-style: none;
    display: flex;
    font-size: 1rem;
}

#navigator-ul a {
    margin-left: 3rem;
    text-decoration: underline;
    color: white;
}

#navigator-ul a:hover {
    font-weight: bold;
}

.current-navigation {
    font-weight: bold;
}

#mobile-navigator {
    display:none;
}

.sidebar-on {
    position: fixed;
    height:100%;
    right: 0;
    top:0;
    width:35%;
    background-color: white;
    color:black;
    display:flex;
    align-items: center;
    flex-direction: column;
    z-index: 3;
    color:black;
}

.sidebar-on .spacer {
    height:10%;
}

.sidebar-on .spacer-small {
    height:5%;
}

.sidebar-on ul {
    display:flex;
    align-items: center;
    flex-direction: column;
    width:100%;
    height:10%;
    list-style: none;
    justify-content: space-between;
}

.sidebar-on a, a:visited {
    text-decoration: none;
}

.sidebar-on a:hover {
    cursor: pointer;
    font-weight: 600;
}

.sidebar-off {
    display:none;
}

.colored-class {
    filter: brightness(0);
    z-index: 1;
    width:18px;
    height:14px;
}

.mobile-navigation-header {
    width:25%;
    height:10vh;
    right:7.5%;
    position:fixed;
    display:flex;
    flex-direction: row-reverse;
    align-items: center;

}


/* Kleine Endgeräte (Smartphones, kleiner als 768px) */
@media (max-width: 750px) { 
    #navigator-ul {
      display:none;
    }

    #mobile-navigator {
        display:unset;
    }

}

@media (min-width: 575.98px) { 

}


