.project-item {
    background-color: white;
    color:black;
    top: 50%; 
    left: 50%;
    height:15rem;
    width:15rem;
}


.project-item:hover{
    cursor:pointer;
    animation: hoverProject .5s;
    transform: scale(1.1);
}

.item-img-container {
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: space-around;
}

.item-img {
  width:85%;
}

.item-img-portrait {
  height:50%;
  width: unset;
}

.item-subtitle {
  color:black;
  position:relative;
  text-align: center;
  top:1rem;
}

@keyframes hoverProject {
    from {
      transform:  scale(1) ;
    }
    to {
      transform: scale(1.1)  ;
    }
}

@media (max-width: 750px) {

  .project-item {
    height:11rem;
    width:11rem;
}

}