#projects {
  display: grid;
  grid-template-rows: minmax(25px, 15vh) minmax(350px, 75vh) minmax(25px, 10vh);
  grid-template-columns: 100%;
  min-height: 350px;
}

#projects-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  bottom: 4%;
}

.project-scroll-container {
  width: unset !important;
  justify-content: space-between;
}

.projects-background {
  position: absolute;
  left: 0;
  background-color: #6c8e94;
  height: -webkit-fill-available;
  width: 100%;
  z-index: -1;
}

#projects-title {
  font-size: 2.5rem;
  margin-top: 1rem;
}

.project-scroll-menu-wrapper {
  width: 100%;
}

.project-scroll-menu-item-wrapper {
  margin: 3.2rem;
  display: flex;
  align-items: center;
}

.arrow-prev,
.arrow-next {
  width: 50%;
  height: 33%;
  position: relative;
  top: 33%;
}

.arrow-next {
  left: 50%;
}

.arrow-prev:hover,
.arrow-next:hover {
  cursor: pointer;
}

@media (max-width: 750px) {
  .project-scroll-menu-item-wrapper {
    padding-bottom: 2rem;
  }

  .projects-background {
    position: absolute;
    top: unset;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #6c8e94;
  }
}
