#home {
  display: grid;
  height: auto;
  min-height: 100vh;
  grid-template-rows: minmax(25px, 6vh) minmax(350px, 44vh) minmax(auto, auto);
}

.logo {
  display: flex;
  font-size: 1.3rem;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #6c8e94;
  border-radius: 50%;
  margin: 10px 0 0 10px;
}

.hero {
  margin-top: 5%;
  height: 44vh;
  min-height: 350px;
  z-index: 1;
}

.hero-buttons {
  margin-top: 8%;
  display: flex;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.hero-buttons a {
  width: 9rem;
  height: 2.5rem;
  align-items: center;
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  border: none;
  box-shadow: 0px 2px 6px black;
}

#project-button {
  font-weight: bold;
  background-color: #6c8e94;
  color: white;
}

.contact-button {
  background-color: white;
  color: black;
  margin-left: 2rem;
}

.hero-big {
  font-size: 4rem;
  white-space: nowrap;
}

.hero-small {
  font-size: 1.6rem;
}

.content {
  height: 50%;
}

#self {
  width: 100%;
  height: 100%;
  transform: translateY(50%);
  transform-origin: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

#center-image {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#me {
  position: absolute;
  right: 0;
  height: 90%;
}

#elipse {
  position: absolute;
  right: 40px;
  height: 90%;
}

.about-me {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  grid-column-start: span 2;
  min-height: 350px;
}

.transparent-bg {
  position: absolute;
  left: 0;
  background-color: white;
  height: -webkit-fill-available;
  width: 100%;
  opacity: 80%;
  z-index: -1;
}

.about-me-content {
  display: grid;
  grid-template-columns: 49% 1% 50%;
  flex-grow: 1;
}

.about-me-header {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: flex-start;
  font-weight: normal;
  font-size: 2.5rem;
  color: #6c8e94;
  text-transform: uppercase;
}

.about-me-text {
  color: black;
  width: 92%;
  word-spacing: 0.1rem;
  font-size: 1rem;
  height: 100%;
  text-align: justify;
  line-height: 3rem;
}

.contact-info-group {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.contact-info-group span {
  top: 0;
}

.about-me-seperator {
  border-left: 2px solid #6c8e94;
  height: 90%;
}

.about-me-bullets {
  align-self: start;
  color: black;
  justify-content: space-around;
  margin-left: 10%;
  height: 100%;
  font-size: 1rem;
}

.about-me-bullets ul {
  list-style-type: none;
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.about-me-bullets li {
  height: 57px;
  padding-left: 80px;
  display: flex;
  align-items: center;
  position: relative;
}

#map-pointer {
  background: url("../assets/map-pointer.png") no-repeat left top;
  background-size: 1.6rem;
  background-position-y: 50%;
}

#suitcase {
  background: url("../assets/suitcase.png") no-repeat left top;
  background-size: 1.6rem;
  background-position-y: 50%;
}

#bachelor {
  background: url("../assets/bachelor.png") no-repeat left top;
  background-size: 1.6rem;
  background-position-y: 50%;
}

#building {
  background: url("../assets/building.png") no-repeat left top;
  background-size: 1.6rem;
  background-position-y: 50%;
}

/* Normale Bildschirme kleingezogen */
@media screen and (max-width: 1350px) {
  .about-me-text {
    color: black;
    width: 92%;
    word-spacing: 0.1rem;
    font-size: 1rem;
    height: 100%;
    text-align: justify;
    line-height: 2rem;
  }

  .about-me-bullets {
    font-size: 1rem;
  }

  #me {
    height: 50%;
    max-height: 250px;
  }

  #elipse {
    height: 50%;
    max-height: 250px;
  }
}

/* Kleine Endgeräte (Smartphones, kleiner als 768px) */
@media (max-width: 750px) {
  .hero-big {
    white-space: unset;
    font-size: 2.25rem;
  }

  .hero-small {
    white-space: nowrap;
    font-size: 1rem;
  }

  #home {
    display: grid;
    height: auto;
    grid-template-rows:
      minmax(25px, 7vh) minmax(200px, 28vh) minmax(120px, 15vh)
      minmax(auto, auto);
    grid-template-columns: 100%;
    align-items: stretch;
  }

  .hero {
    margin-top: unset;
    grid-column-start: 1;
  }

  .about-me {
    grid-column-start: unset;
    max-height: 100%;
  }

  .hero-buttons {
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 1.1rem;
    width: 100%;
  }

  .hero-buttons a {
    width: 100%;
    height: 2rem;
    align-items: center;
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    border: none;
    box-shadow: 0px 2px 6px black;
  }

  #project-button {
    font-weight: bold;
    background-color: #6c8e94;
    color: white;
  }

  .contact-button {
    background-color: white;
    color: black;
    width: 100%;
    margin-left: unset;
    margin-top: 4%;
  }

  #center-image {
    height: 80%;
    width: 50%;
  }

  .about-me-content {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
  }

  .about-me-header {
    margin: unset;
    margin-top: 80px;
    width: 100%;
  }

  .about-me-seperator {
    border-bottom: 2px solid #6c8e94;
    border-left: unset;
    height: unset;
  }

  #self {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  #me {
    position: absolute;
    right: unset;
    height: 100%;
    max-height: 160px;
  }

  #elipse {
    position: absolute;
    right: unset;
    margin-right: 40px;
    height: 100%;
    max-height: 160px;
  }

  .about-me-bullets {
    color: black;
    height: 60%;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .about-me-bullets ul {
    list-style-type: none;
    height: 80%;
    flex-direction: column;
    justify-content: space-around;
  }

  .about-me-bullets li {
    font-size: 1rem;
    height: 60px;
    padding-left: 60px;
  }

  .transparent-bg {
  }

  .about-me-text {
    color: black;
    width: 100%;
    font-size: 1rem;
    line-height: unset;
    height: 100%;
    margin-top: 2rem;
  }

  #map-pointer,
  #suitcase,
  #bachelor,
  #building {
    background-size: 1rem;
  }
}

/* Kleine Endgeräte (Tablets, kleiner als 992px) */

@media (max-width: 991.98px) {
}

/* Große Endgeräte (Laptops, Bildschirme, kleiner als 1200px) */

@media (max-width: 1199.98px) {
}
