.modal-data-protection {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

.modal-content-data-protection {
  height: 60vh;
  width: 60vw;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.data-protection-content {
    color:black;
    align-items: left;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1rem;
    
}

.data-protection-content h1 {
  margin-bottom: 2rem;
}

.data-protection-content h3 {
  margin: 1rem 0;
}


.data-protection-content p ul {
  margin-bottom: 0.5rem;
}

.data-protection-conten a, p {
  text-align: justify;
}

.dot-list {
  margin: 1rem 0 1rem 3rem;
}

@media (max-width: 750px) { 

  .modal-content-data-protection {
    height:90vh;
    width:90vw;
  }

  .data-protection-content a, p {
    text-align: center;
    align-items: center;
  }

  .data-protection-content {
    text-align: center;
    align-items: center; 
    max-width: 100%;
    margin: 1rem;
  }

  .data-protection-content li {
    text-align: left;
  }

}
