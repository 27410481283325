* {
  margin:0;
  padding:0;
}

html {
  scroll-behavior: smooth;
  font-size: 100%;
}

body {
  margin: 0;
  font-family: 'Source Code Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus, input:focus{
  outline: none;
}

video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed; 
  left: 0px;
  top: 0px;
  z-index: -1;
  opacity: 50%;
}


