.modal-content-small {
    height:35vh;
    width:35vw;
    background-color: white;
    color:black;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }
  
  .small-input {
    width:8rem;
    margin-left:20%;
  }

  .center-input-small {
    color:black;
    display:flex;
    justify-content: space-around;
    align-items: center;
    width:100%;
  }

  .center-input-small {
    color:black;
    display:flex;
    justify-content: space-around;
    width:100%;
    position: relative;
    align-items: center;
  }

  .center-container-small {
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    flex-direction: column;
  }


  .left-container-small {
    top:0;
    flex:1;
    color:black;
    position: absolute;
    left:4%;
    width:12rem;
    border-bottom: 1px solid #6C8E94;
    padding-left:0.5em;
    padding-bottom: 0.3em;
    font-weight: bold;
  }

  .left-container-small {
    top:0;
    flex:1;
    color:black;
    position: absolute;
    left:23%;
    width:7rem;
    border-bottom: 1px solid #6C8E94;
    padding-left:0.5em;
    padding-bottom: 0.3em;
    font-weight: bold;
  }


/* Normale Bildschirme kleingezogen */
@media screen and (max-width: 1350px) {

    .left-container-small {
      position: unset
    }

    .small-input {
      width:100%;
      margin-left: unset;
    }

    .modal-content-small {
      height:40vh;
      width:60vw;
      text-align: center;
      overflow-y: scroll;
    }

    .left-container-small {
      margin-bottom: 0.5rem;
    }

  }