.link {
    text-decoration: underline;
    grid-row-start: 3;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
}

.link p {
    margin-left: 3rem;
}

.link p:hover {
    cursor:pointer;
    font-weight: bold;
}

.footer-modal {
    position: absolute;
    width:50vh;
    height:50vh;
    background-color: white;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}


.footer-modal-content {
  height:60vh;
  width:50vw;
  background-color: white;
  color:black;
  display:flex;
  flex-direction: column;
  align-items: center;
}