.contact {
  width: 85%;
  margin: 0 auto;
  display: grid;
  height: 100vh;
  grid-template-rows: minmax(25px,6vh) auto 10vh;
  grid-template-columns: 100%;
  align-items: stretch;
  position: relative;
}

.contact-content {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height:630px;
}

.slogan {
  position: absolute;
  top: 8%;
  left: 0;
  font-size: 3.7rem;
  width: 40rem;
  word-spacing: 0.5rem;
}

.contact-pane {
  display: flex;
  justify-content: space-around;
  height: 75%;
  align-items: center;
  width: 55%;
}

.contact-form {
  height: 75%;
  width: 60%;
  opacity: 90%;
  background-color: white;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
  color:black;
  display:flex;
  align-items: center;
  justify-content: space-around;
}

.contact-form form {
  width:80%;
  display:flex;
  flex-direction: column;
  height:90%;
  justify-content: space-around;
}

#contact-and-bullets {
  position: unset;
  height:100%;
}

.contact-form input {
  width:100%;
  border:none;
  border-bottom: 1px solid #6C8E94;
  padding-left: 0.4rem;
  height:1.5rem;
}

.contact-form textarea {
  width:100%;
  border:none;
  border: 1px solid #6C8E94;
  resize: none;
  padding-left: 0.4rem;
  padding-top: 0.5rem;
}

.contact-info {
  height: 100%;
  width:50%;
  opacity: 90%;
  background-color: #6C8E94;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.contact-info-content {
  top:22.5%;
  height:30%;
  display:flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  position: relative;
}


.contact-info span {
  position: absolute;
  top: 12.5%;
  font-weight:bold;
}

.contact-info-map {
  width:100%;
  border:none;
  position: relative;
  bottom: 12.5%;
  height:30%;
}

.contact-bullets {
  align-self: start;
  color: white;
  display:flex;
  justify-content: space-between;
  width:100%;
  height:80%;
}

.contact-bullets ul {
  list-style-type: none;
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-bullets li {
  height: 57px;
  padding-left: 35px;
  padding-top: 3px;
  font-size: 0.9em;
  display:flex;
  align-items: center;
  position: relative;
}

#pointer-white {
  background: url("../assets/map-white.png") no-repeat left top;
  background-size: 1.3rem;
  background-position-y: 50%;
}

#phone {
  background: url("../assets/phone.png") no-repeat left top;
  background-size: 1.3rem;
  background-position-y: 50%;
}

#mail {
  background: url("../assets/mail.png") no-repeat left top;
  background-size: 1.3rem;
  background-position-y: 50%;
}

.contact-info-content img {
  overflow: hidden;
}



/* Kleingezogene große bildschirme */
@media screen and (max-width:1350px) {

  .contact {
    height: 130vh;
  }

  .contact-info-content {
    flex-direction: row;
  }

  .contact-content {
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }

  .contact-pane {
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    height:100%;
  }

  .contact-form {
    border-radius: 2rem;
    width:100%;
    height:45%;
  }

  .contact-info {
    border-radius: 2rem;
    width:100%;
    height:45%;

  }

  .slogan {
    position: unset;
    top: 2%;
    left: 0;
    font-size: 2.4rem;
    width: 40rem;
    word-spacing: 0.5rem;
    text-align: center;
  }

  .contact-info-content span {
    left:44%;
  }

  #contact-and-bullets {
    width:100%;
  }

  .contact-bullets ul {
    width:80%;
  }

  .contact-bullets {
    flex-direction: column;
    align-items: center;
  }

}

/* Normale Bildschirme kleingezogen */
@media screen and (max-width: 1350px) {

  .contact {
    height: 200vh;
  }

  .slogan {
    width:80%;
    text-align: center;
  }

  .contact-pane {
    width:100%;
    height:100%;
  }

  .contact-form form {
    align-items: center;
  }  
}


@media (max-width: 767.98px) { }

/* Kleine Endgeräte (Tablets, kleiner als 992px) */

@media (max-width: 991.98px) {  
  

}

/* Große Endgeräte (Laptops, Bildschirme, kleiner als 1200px) */

@media (max-width: 1199.98px) { }


