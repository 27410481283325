.modal {
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display:flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
  height:80vh;
  width:80vw;
  background-color: white;
  color:black;
  display:flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.close {
  color:black;
  position: sticky;
  align-self: flex-end;
  padding: 1rem;
  top: 0;
  z-index: 1;
}

.close img {
  height:1.1rem;
  width:1.1rem;
}

.close:hover {
  cursor:pointer;
}

.center-container {
  margin-top:3%;
  width: 10rem;
  display:flex;
  align-items: left;
  position: relative;
}

.center-input {
  color:black;
  width: 10rem;
  position: relative;
}

.large-input {
  display: inline-block;
  width:40rem;
  text-align: justify;
}

.form-group {
  position: relative;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.last-group {
  flex:1;
  align-items: flex-end;
  margin-bottom: 3rem;
}


.center-container-large {
  width:80%;
  height:100%;
  display:flex;
  align-items: center;
  margin-top:4rem;
  flex-direction: column;
}

.center-container img {
  width:100%;
}

.center-container .item-img-portrait {
  max-height:5rem;
  width:unset;
  align-self: center;
  height:100%;
}

.center-container form {
  width:100%;
}

.center-container input {
  width:100%;
  align-self: center;
}

.left-container {
  top:0;
  flex:1;
  color:black;
  position: absolute;
  left:23%;
  width:10rem;
  border-bottom: 1px solid #6C8E94;
  padding-left:0.5em;
  padding-bottom: 0.3em;
  font-weight: bold;
}

.contact-button-green {
  background-color: #6C8E94;
  color: white;
  width: 9rem;
  height: 2.5rem;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  border: none;
  box-shadow: -4px 3px 8px -2px #6C8E94;
  display: flex;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
}

.contact-button-green:hover {
  cursor: pointer;
}


/* Normale Bildschirme kleingezogen */
@media screen and (max-width: 1350px) {

  .form-group {
    display:flex;
    flex-direction: column;
    align-items: center;

  }

  .left-container {
    position: unset;
    width:100%;
    margin-bottom: 0.5rem;
  }

  .center-input {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-button-green {
    margin-bottom: 1rem;
  }
  .large-input {
    display:flex;
    width:100%;
  }

  .center-container {
    display:flex;
    align-items: center; 
    justify-content: space-around;
  }
}

/* Normale Bildschirme kleingezogen */
@media screen and (max-width: 750px) {
  .modal {
    font-size: 0.8rem;
  }
}